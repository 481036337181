
import resize from '@/directive/resizeDialog'
import preventReClick from '@/directive/preventReClick'

const install = function(Vue:any) {
  Vue.directive('el-resize-dialog', resize)
  Vue.directive('preventReClick', preventReClick)
}

export default install
