export default {
  inserted(el:any, binding:any, vnode:any) {
    el.addEventListener('click', () => {
      console.log(1111, el.disabled)
      if (!el.disabled) {
        el.disabled = true

        if (binding.value <= 0) {
          return
        }

        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
}
