import request from '@/utils/request'
import Md5 from '@/utils/md5'

export const getUserInfo = (data: any) =>
  request({
    url: '/user/info',
    method: 'post',
    data
  })

export const login = (data: any) => {
  const { username, password } = data
  return request({
    url: '/user/login',
    method: 'post',
    data: {
      username,
      password: Md5.Instance.get_md5(password)
    }
  })
}

export const logout = () =>
  request({
    url: '/users/logout',
    method: 'post'
  })
