import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  // mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
      meta: { hidden: true }
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      meta: { hidden: true }
    },
    {
      path: '/',
      component: Layout,
      redirect: '/dashboard',
      children: [
        {
          path: 'dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
          meta: {
            title: '管理首页',
            icon: 'dashboard'
          }
        }
      ]
    },
    {
      path: '/category',
      component: Layout,
      redirect: '/category/index',
      meta: {
        title: '分类管理',
        icon: 'example'
      },
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/category/index.vue'),
          meta: {
            title: '分类管理',
            icon: 'example'
          }
        }
      ]
    },
    {
      path: '/organization',
      component: Layout,
      redirect: '/organization/index',
      meta: {
        title: '组织机构',
        icon: 'example'
      },
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/organization/index.vue'),
          meta: {
            title: '组织机构',
            icon: 'example'
          }
        }
      ]
    },
    {
      path: '/guest',
      component: Layout,
      redirect: '/guest/index',
      meta: {
        title: '嘉宾',
        icon: 'example'
      },
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/guest/index.vue'),
          meta: {
            title: '嘉宾管理',
            icon: 'example'
          }
        }
      ]
    },
    {
      path: '/meeting',
      component: Layout,
      redirect: '/meeting/index',
      meta: {
        title: '会议管理',
        icon: 'example'
      },
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/meeting/index.vue'),
          meta: {
            title: '会议管理',
            icon: 'example'
          }
        }
        // {
        //   path: 'ticket',
        //   component: () => import(/* webpackChunkName: "tree" */ '@/views/meeting/meeting_ticket.vue'),
        //   meta: {
        //     title: '票种管理',
        //     icon: 'example'
        //   }
        // }
        // ,
        // {
        //   path: 'subfm',
        //   component: () => import(/* webpackChunkName: "tree" */ '@/views/meeting/meeting_subforum.vue'),
        //   meta: {
        //     title: '日程管理',
        //     icon: 'example'
        //   }
        // }
      ]
    },
    {
      path: '*',
      redirect: '/404',
      meta: { hidden: true }
    }
  ]
})
